import ODR_LOGO_BLACK from '../assets/images/odrescue-logo-black.svg';
import ODR_PRODUCT from '../assets/images/ODRescue_Box_on_Floor_Right.png';
import ODR_POUCH from '../assets/images/ODRescue_Pouch_with_Floor_Front.png';
import NARCAN_LANDING from '../assets/images/narcan_landing.png';
import FFF_LOGO_FOOTER_BOAT from '../assets/images/footer-logo-fff.png';

export const IMAGES_URL = {
    ODR_LOGO_BLACK: ODR_LOGO_BLACK,
    ODR_PRODUCT: ODR_PRODUCT,
    NARCAN_LANDING: NARCAN_LANDING,
    FFF_LOGO_FOOTER_BOAT: FFF_LOGO_FOOTER_BOAT,
    ODR_POUCH: ODR_POUCH
};